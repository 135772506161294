export const data = {
    "home-studio-services": {
        card1: {
            title: "Сложные веб-сервисы",
            title_ul: "Владеем опытом создания сложных веб-проектов:",
            sub_title_li: [
                "Образовательные онлайн школы",
                "Онлайн аукционы (товары, автомобили)",
                "Маркетплэйсы и финансовая платформа",
                "Платформа B2B",
                "Любые нестандартные решения",
            ],
        },
        card2: {
            title:
                "Компания имеет на своем активе проекты, которые запущены уже более 5 лет и успешно работают на рынке.",
        },
        card3: {
            title4:
                "Задача команды не только разработать продукт, но и грамотно протестировать и оптимизировать его работу, чтобы пользователи получили максимальное удовольствие от использования веб-сервиса.",
        },
        card4: {
            title: "Интернет-магазины",
            title_ul: "Создание Интернет-Магазина под ключ:",
            sub_title_li: [
                "Продающая структура",
                "Легкое управление товарами и заказами",
                "Любые виды интеграций",
                "Индивидуальный функционал",
                "Бесплатная тех. поддержка",
            ],
        },
        card5: {
            title:
                "Мы разработаем уникальный дизайн, который подчеркнет индивидуальность вашего бренда и поможет привлечь больше клиентов.",
        },
        card6: {
            title: "Корпоративный сайт",
            title_ul: "Создание корпоративного сайта:",
            sub_title_li: [
                "Создадим запоминающийся сайт (не как у всех)",
                "Формируем лицо компании в интернете, для любой сферы бизнеса",
                "Повысим онлайн продажи компании",
                "Создадим любой функционал",
            ],
        },
        card7: {
            title: "Любой функционал",
        },
        card8: {
            title:
                "Мы используем современные технологии и подходы, чтобы создавать сайты, которые будут соответствовать требованиям и ожиданиям клиентов.",
        },
        card9: {
            title: "Лендинг под ключ",
            title_ul: "Создание Лендинга, сайта визитки:",
            sub_title_li: [
                "для начинающих компаний или одних услуг;",
                "с продающим дизайном и ставкой на выбор привлечение;",
                "ориентация на точную и конкретную аудиторию;",
                "техническая поддержка после запуска.",
                "Домен сайта в подарок.",
            ],
        },
        card10: {
            title: "Разработка: от 7-14 дней",
        },
        card11: {
            title: "Приложения",
            title_ul: "Разработка мобильных приложений:",
            sub_title_li: [
                "iOS/Android applications.",
                "Publication in AppStore/PlayMarket.",
                "Applications for business.",
                "We will conduct analytics + draw up technical specifications.",
                "Turnkey development on modern technologies.",
                "UX/UI design.",
            ],
        },
        card12: {
            title: "Поддержка проекта 24/7",
        },
        card13: {
            title: "Публикация в AppStore/PlayMarket.",
        },
        card14: {
            first_title: "CMS",
            sub_title_li: [
                "Создание сайтов на популярных движках",
                "Написание собственной платформы CMS",
                "Удобство в использовании",
                "Безопасность",
                "Настройка и тестировании",
            ],
        },
        card15: {
            title: "Консультация и подбор CMS под ваш проект",
        },
    },
};