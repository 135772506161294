import { memo, useRef, useState } from "react";
import HomeConsultationSlider from "./Home-consultation-slider";
import "./Home-consultation.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const HomeConsultation = () => {
    const [full_name, setFull_name] = useState("");
    const [phone_num, setPhone_num] = useState("");
    const [content, setContent] = useState("");
    const form = useRef();
    const { t } = useTranslation();


    const fetchData = async (e) => {
        e.preventDefault();
        const data = {
            full_name: full_name,
            phone: phone_num,
            content: content,
        };

        try {
            await axios.post(
                "https://api-usertech.ru/api/sayts/consultatsiya_post_sayts/",
                data,
                {
                    headers: {
                        "Accept-Language": "ru",
                    },
                }
            );

            setFull_name("");
            setPhone_num("");
            setContent("");
        } catch (error) {
            console.log(error);
        }
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_ey4dhzi",
                "template_qhxofd9",
                form.current,
                "TOoXAkhkvRiMQtuey"
            )
            .then(
                (result) => {
                    console.log("send message");
                },
                (error) => {
                    console.log(error.text);
                }
            );
        fetchData(e);
    };

    return (
        <div className="consultation px-4 sm:px-0 w-full md:w-[1280px] md:flex-row md:mx-auto">
            <div className="flex md:flex-row flex-col justify-between">
                <div className="consultation-form_l rounded-[10px] md:rounded-[20px] my-12 md:mr-8">
                    <div className="box md:w-[80%] mx-auto">
                        <div className="form-head">
                            <h1 className="font-medium text-[18px] md:text-[32px] text-white">{t("consultation.title")}</h1>
                        </div>
                        <div className="form-body">
                            <form onSubmit={sendEmail} ref={form}>
                                <p>
                                    <input
                                        type="text"
                                        placeholder={t("consultation.input_name")}
                                        required
                                        onChange={(e) => setFull_name(e.target.value)}
                                        value={full_name}
                                        name="user_name"
                                        className="rounded-[5px] md:rounded-[20px] h-[35px] md:h-[51px] placeholder:text-[14px] md:placeholder:text-[20px]"
                                    />
                                </p>
                                <p>
                                    <input
                                        type="text"
                                        placeholder={t("consultation.input_phone")}
                                        required
                                        onChange={(e) => setPhone_num(e.target.value)}
                                        value={phone_num}
                                        name="user_number"
                                        className="rounded-[5px] md:rounded-[20px] h-[35px] md:h-[51px] placeholder:text-[14px] md:placeholder:text-[20px]"
                                    />
                                </p>
                                <p>
                                    <textarea
                                        placeholder={t("consultation.input_questions")}
                                        required
                                        onChange={(e) => setContent(e.target.value)}
                                        value={content}
                                        name="message"
                                        className="rounded-[5px] md:rounded-[20px] h-[35px] md:h-[51px] placeholder:text-[14px] md:placeholder:text-[20px]"
                                    ></textarea>
                                </p>
                                <p>
                                    <div className="flex items-center justify-center w-full">
                                        <input
                                            type="submit"
                                            value={t("consultation.input_submit")}
                                            className="rounded-[5px] md:rounded-[20px] cursor-pointer active:bg-[#3E7AE7] bg-white active:scale-[0.94] transition-all duration-400 ease-linear placeholder:text-[14px] md:placeholder:text-[20px]"
                                        />
                                    </div>
                                </p>
                                <p className="text-[12px] sm:text-[14px] text-white/50 font-normal text-start px-4 sm:px-2">{t("consultation.description")}</p>
                            </form>

                        </div>
                    </div>
                </div>

                <div className="consultation-slider_r rounded-[10px] md:rounded-[20px] my-12 md:ml-8">
                    <HomeConsultationSlider />
                </div>
            </div>
        </div>
    );
};

export default memo(HomeConsultation);
