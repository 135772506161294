import React, { useEffect, useState } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { HiMail } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import "./feedback.css";
import { toast } from 'sonner';
import axios from 'axios';

const ClientFeedback = ({ closeModal }) => {
    const { t } = useTranslation();
    const [postData, setPostData] = useState({ title: '', email: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [isFollow, setIsFollow] = useState(true);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration in milliseconds
            offset: 100, // Change when the element triggers the animation (0 = at the top of the window)
        });
    }, []);

    const handleModal = () => {
        closeModal();
        document.body.style.overflow = "auto";
    }


    const fetchData = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const data = {
            name: postData.title,
            email: postData.email
        };

        try {
            await axios.post(
                "https://api-usertech.ru/api/admin_panel/subscription/",
                data,
                {
                    headers: {
                        "Accept-Language": "ru",
                    },
                }
            );
            setPostData({ title: '', email: '' });
            setIsLoading(false);
            handleModal();
            toast("Ваше сообщение отправлено");

        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    };
    const fetchData2 = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const data = {
            email: postData.email
        };

        try {
            await axios.put(
                "https://api-usertech.ru/api/admin_panel/subscription/",
                data,
                {
                    headers: {
                        "Accept-Language": "ru",
                    },
                }
            );
            setPostData({ title: '', email: '' });
            setIsLoading(false);
            handleModal();
            toast("Ваше сообщение отправлено");

        } catch (error) {
            toast.error("Эта электронная почта не была отмечена как подписка")
            setIsLoading(false);
            console.log(error);
        }
    };
    return (
        <div className='fixed bg-black/50 z-[999999999] inset-0 w-full h-screen flex items-center justify-center'>
            <div
                data-aos="flip-lef relative"
                className="w-[96%] sm:w-[40%] flex md:flex-row flex-col min-h-40"
            >

                {
                    isFollow ? (
                        <div className="contact-form relative ">
                            <div
                                onClick={closeModal}
                                className="w-[24px] rounded-tr-lg sm:rounded-tr-[20px] sm:w-[54px] z-[99999] cursor-pointer h-[24px] sm:h-[54px] flex items-center justify-center bg-white absolute top-0 right-0"
                            >
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 24 24"
                                    fill="#7a7a7a"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sm:hidden"
                                >
                                    <path
                                        d="M0.659322 0.659322C0.868035 0.450311 1.11591 0.284497 1.38876 0.171365C1.66161 0.0582322 1.95409 0 2.24946 0C2.54484 0 2.83732 0.0582322 3.11017 0.171365C3.38302 0.284497 3.63089 0.450311 3.8396 0.659322L12.0003 8.81705L20.1611 0.659322C20.3699 0.450502 20.6178 0.284856 20.8906 0.171843C21.1635 0.0588306 21.4559 0.000663822 21.7512 0.000663816C22.0465 0.000663811 22.3389 0.0588306 22.6118 0.171843C22.8846 0.284856 23.1325 0.450502 23.3413 0.659322C23.5502 0.868142 23.7158 1.11605 23.8288 1.38888C23.9418 1.66172 24 1.95415 24 2.24946C24 2.54478 23.9418 2.83721 23.8288 3.11004C23.7158 3.38288 23.5502 3.63078 23.3413 3.8396L15.1836 12.0003L23.3413 20.1611C23.7631 20.5828 24 21.1548 24 21.7512C24 22.3476 23.7631 22.9196 23.3413 23.3413C22.9196 23.7631 22.3476 24 21.7512 24C21.1548 24 20.5828 23.7631 20.1611 23.3413L12.0003 15.1836L3.8396 23.3413C3.41787 23.7631 2.84588 24 2.24946 24C1.65304 24 1.08105 23.7631 0.659322 23.3413C0.23759 22.9196 0.000663816 22.3476 0.000663816 21.7512C0.000663816 21.1548 0.23759 20.5828 0.659322 20.1611L8.81705 12.0003L0.659322 3.8396C0.450311 3.63089 0.284497 3.38302 0.171365 3.11017C0.0582322 2.83732 0 2.54484 0 2.24946C0 1.95409 0.0582322 1.66161 0.171365 1.38876C0.284497 1.11591 0.450311 0.868035 0.659322 0.659322Z"
                                        fill="black"
                                    />
                                </svg>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sm:flex hidden"
                                >
                                    <path
                                        d="M0.659322 0.659322C0.868035 0.450311 1.11591 0.284497 1.38876 0.171365C1.66161 0.0582322 1.95409 0 2.24946 0C2.54484 0 2.83732 0.0582322 3.11017 0.171365C3.38302 0.284497 3.63089 0.450311 3.8396 0.659322L12.0003 8.81705L20.1611 0.659322C20.3699 0.450502 20.6178 0.284856 20.8906 0.171843C21.1635 0.0588306 21.4559 0.000663822 21.7512 0.000663816C22.0465 0.000663811 22.3389 0.0588306 22.6118 0.171843C22.8846 0.284856 23.1325 0.450502 23.3413 0.659322C23.5502 0.868142 23.7158 1.11605 23.8288 1.38888C23.9418 1.66172 24 1.95415 24 2.24946C24 2.54478 23.9418 2.83721 23.8288 3.11004C23.7158 3.38288 23.5502 3.63078 23.3413 3.8396L15.1836 12.0003L23.3413 20.1611C23.7631 20.5828 24 21.1548 24 21.7512C24 22.3476 23.7631 22.9196 23.3413 23.3413C22.9196 23.7631 22.3476 24 21.7512 24C21.1548 24 20.5828 23.7631 20.1611 23.3413L12.0003 15.1836L3.8396 23.3413C3.41787 23.7631 2.84588 24 2.24946 24C1.65304 24 1.08105 23.7631 0.659322 23.3413C0.23759 22.9196 0.000663816 22.3476 0.000663816 21.7512C0.000663816 21.1548 0.23759 20.5828 0.659322 20.1611L8.81705 12.0003L0.659322 3.8396C0.450311 3.63089 0.284497 3.38302 0.171365 3.11017C0.0582322 2.83732 0 2.54484 0 2.24946C0 1.95409 0.0582322 1.66161 0.171365 1.38876C0.284497 1.11591 0.450311 0.868035 0.659322 0.659322Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                            <div className="section-container">
                                <form className="contact-form-wrap gap-y-6" onSubmit={fetchData}>
                                    <h1 className='text-[#7a7a7a] text-center font-semibold text-[17px] sm:text-[20px]'>{isFollow ? "Подпишитесь на рассылку и получите скидку" : "Отмените подписку"}</h1>
                                    {/* Text inputBox */}
                                    <div className="input-box">
                                        <div className="input-box-card">
                                            <h4>
                                                {t("Contact.form.input_first_name")} <span>*</span>
                                            </h4>
                                            <div className="input-wrap">
                                                <input
                                                    type="text"
                                                    id=" name"
                                                    name="firstname"
                                                    placeholder={t("Contact.form.input_first_name")}
                                                    required
                                                    onChange={(e) => setPostData({ ...postData, title: e.target.value })}
                                                    value={postData.title}
                                                />
                                            </div>
                                        </div>

                                        <div className="input-box-card">
                                            <div className="input-wrap">
                                                <input
                                                    type="email"
                                                    placeholder={t("Contact.form.input_email")}
                                                    required
                                                    onChange={(e) => setPostData({ ...postData, email: e.target.value })}
                                                    value={postData.email}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className='w-full flex flex-col gap-y-3 items-center justify-center mt-10'>
                                        <button type="button" onClick={() => setIsFollow(false)} className='text-[#7a7a7a] text-end font-semibold text-[17px] sm:text-[20px]'>{isFollow ? "Отменить подписку" : "Подписаться"}</button>
                                        <div className="btn-box min-w-[300px]">
                                            <input disabled={isLoading} type="submit" value={isLoading ? "Загрузка..." : t("Contact.form.input_button")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (

                        <div className="contact-form relative ">
                            <div
                                onClick={closeModal}
                                className="w-[24px] rounded-tr-lg sm:rounded-tr-[20px] sm:w-[54px] z-[99999] cursor-pointer h-[24px] sm:h-[54px] flex items-center justify-center bg-white absolute top-0 right-0"
                            >
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 24 24"
                                    fill="#7a7a7a"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sm:hidden"
                                >
                                    <path
                                        d="M0.659322 0.659322C0.868035 0.450311 1.11591 0.284497 1.38876 0.171365C1.66161 0.0582322 1.95409 0 2.24946 0C2.54484 0 2.83732 0.0582322 3.11017 0.171365C3.38302 0.284497 3.63089 0.450311 3.8396 0.659322L12.0003 8.81705L20.1611 0.659322C20.3699 0.450502 20.6178 0.284856 20.8906 0.171843C21.1635 0.0588306 21.4559 0.000663822 21.7512 0.000663816C22.0465 0.000663811 22.3389 0.0588306 22.6118 0.171843C22.8846 0.284856 23.1325 0.450502 23.3413 0.659322C23.5502 0.868142 23.7158 1.11605 23.8288 1.38888C23.9418 1.66172 24 1.95415 24 2.24946C24 2.54478 23.9418 2.83721 23.8288 3.11004C23.7158 3.38288 23.5502 3.63078 23.3413 3.8396L15.1836 12.0003L23.3413 20.1611C23.7631 20.5828 24 21.1548 24 21.7512C24 22.3476 23.7631 22.9196 23.3413 23.3413C22.9196 23.7631 22.3476 24 21.7512 24C21.1548 24 20.5828 23.7631 20.1611 23.3413L12.0003 15.1836L3.8396 23.3413C3.41787 23.7631 2.84588 24 2.24946 24C1.65304 24 1.08105 23.7631 0.659322 23.3413C0.23759 22.9196 0.000663816 22.3476 0.000663816 21.7512C0.000663816 21.1548 0.23759 20.5828 0.659322 20.1611L8.81705 12.0003L0.659322 3.8396C0.450311 3.63089 0.284497 3.38302 0.171365 3.11017C0.0582322 2.83732 0 2.54484 0 2.24946C0 1.95409 0.0582322 1.66161 0.171365 1.38876C0.284497 1.11591 0.450311 0.868035 0.659322 0.659322Z"
                                        fill="black"
                                    />
                                </svg>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sm:flex hidden"
                                >
                                    <path
                                        d="M0.659322 0.659322C0.868035 0.450311 1.11591 0.284497 1.38876 0.171365C1.66161 0.0582322 1.95409 0 2.24946 0C2.54484 0 2.83732 0.0582322 3.11017 0.171365C3.38302 0.284497 3.63089 0.450311 3.8396 0.659322L12.0003 8.81705L20.1611 0.659322C20.3699 0.450502 20.6178 0.284856 20.8906 0.171843C21.1635 0.0588306 21.4559 0.000663822 21.7512 0.000663816C22.0465 0.000663811 22.3389 0.0588306 22.6118 0.171843C22.8846 0.284856 23.1325 0.450502 23.3413 0.659322C23.5502 0.868142 23.7158 1.11605 23.8288 1.38888C23.9418 1.66172 24 1.95415 24 2.24946C24 2.54478 23.9418 2.83721 23.8288 3.11004C23.7158 3.38288 23.5502 3.63078 23.3413 3.8396L15.1836 12.0003L23.3413 20.1611C23.7631 20.5828 24 21.1548 24 21.7512C24 22.3476 23.7631 22.9196 23.3413 23.3413C22.9196 23.7631 22.3476 24 21.7512 24C21.1548 24 20.5828 23.7631 20.1611 23.3413L12.0003 15.1836L3.8396 23.3413C3.41787 23.7631 2.84588 24 2.24946 24C1.65304 24 1.08105 23.7631 0.659322 23.3413C0.23759 22.9196 0.000663816 22.3476 0.000663816 21.7512C0.000663816 21.1548 0.23759 20.5828 0.659322 20.1611L8.81705 12.0003L0.659322 3.8396C0.450311 3.63089 0.284497 3.38302 0.171365 3.11017C0.0582322 2.83732 0 2.54484 0 2.24946C0 1.95409 0.0582322 1.66161 0.171365 1.38876C0.284497 1.11591 0.450311 0.868035 0.659322 0.659322Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                            <div className="section-container">
                                <form className="contact-form-wrap gap-y-6" onSubmit={fetchData2}>
                                    <h1 className='text-[#7a7a7a] text-center font-semibold text-[17px] sm:text-[20px]'>{isFollow ? "Подпишитесь на рассылку и получите скидку" : "Отмените подписку"}</h1>
                                    {/* Text inputBox */}
                                    <div className="input-box">
                                        <div className="input-box-card">

                                            <div className="input-wrap">
                                                <input
                                                    type="email"
                                                    placeholder={t("Contact.form.input_email")}
                                                    required
                                                    onChange={(e) => setPostData({ ...postData, email: e.target.value })}
                                                    value={postData.email}
                                                />
                                            </div>
                                        </div>
                                        <div className="input-box-card ">
                                            {/* <h4>
                                                {t("Contact.form.input_first_name")} <span>*</span>
                                            </h4>
                                            <div className="input-wrap">
                                                <input
                                                    type="text"
                                                    id=" name"
                                                    name="firstname"
                                                    placeholder={t("Contact.form.input_first_name")}
                                                    required
                                                    onChange={(e) => setPostData({ ...postData, title: e.target.value })}
                                                    value={postData.title}
                                                />
                                            </div> */}
                                        </div>


                                    </div>


                                    <div className='w-full flex flex-col gap-y-3 items-center justify-center mt-10'>
                                        <button type="button" onClick={() => setIsFollow(true)} className='text-[#7a7a7a] text-end font-semibold text-[17px] sm:text-[20px]'>{isFollow ? "Отменить подписку" : "Подписаться"}</button>
                                        <div className="btn-box min-w-[300px]">
                                            <input disabled={isLoading} type="submit" value={isLoading ? "Загрузка..." : t("Contact.form.input_button")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ClientFeedback