import "./Home-customers.css";
import gifRobot from "../../assets/images/Home-image/robot.gif";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import { memo } from "react";
const HomeCustomers = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 500px)");
  const cardData = [
    {
      title: t("home-customers-card.card1.title"),
      path: `/services/${t("home-customers-card.card1.path")}/8`,
      body: [
        t("home-customers-card.card1.li1"),
        t("home-customers-card.card1.li2"),
        t("home-customers-card.card1.li3"),
        t("home-customers-card.card1.li4"),
        t("home-customers-card.card1.li5"),
        t("home-customers-card.card1.li6"),
        t("home-customers-card.card1.li7"),
        t("home-customers-card.card1.li8"),
      ],
    },
    {
      title: t("home-customers-card.card2.title"),
      path: `/services/${t("home-customers-card.card1.path")}/36`,
      body: [
        t("home-customers-card.card2.li1"),
        t("home-customers-card.card2.li2"),
        t("home-customers-card.card2.li3"),
        t("home-customers-card.card2.li4"),
        t("home-customers-card.card2.li5"),
        t("home-customers-card.card2.li6"),
        t("home-customers-card.card2.li7"),
        t("home-customers-card.card2.li8"),
      ],
    },
    {
      title: t("home-customers-card.card3.title"),
      path: `/services/${t("home-customers-card.card1.path")}/94`,
      body: [
        t("home-customers-card.card3.li1"),
        t("home-customers-card.card3.li2"),
        t("home-customers-card.card3.li3"),
        t("home-customers-card.card3.li4"),
        t("home-customers-card.card3.li5"),
        t("home-customers-card.card3.li6"),
        t("home-customers-card.card3.li7"),
      ],
    },
  ];
  return (
    <div className="bg-maingrey">
      {/* Laptop */}
      <div className=" w-[90%] sm:w-full flex flex-col bg-maingrey px-4">
        <div className="md:w-[1140px] mx-auto py-[80px]">
          <div className="relative sm:pb-[100px]">
            <h1 className="font-semibold text-[32px] md:text-[60px]">{t("customers-wrap.left.title1").split(",")[0].trim() + ","}</h1>
            <h1 className="font-semibold text-[32px] md:text-[60px] text-mainblue">{t("customers-wrap.left.title1").split(",")[1]}</h1>


            <div className="absolute top-36 left-[-50px] md:flex hidden">
              <svg width="100" height="110" viewBox="0 0 100 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.6165 2.93327C10.4187 -0.669399 -12.6949 18.5487 16.0004 48.5032C46.7846 80.6381 91.2881 100.062 96.6723 106.706" stroke="#3E7AE7" stroke-width="4" />
                <path d="M70.9138 107.548L97.5161 107.672L97.6332 82.4612" stroke="#3E7AE7" stroke-width="4" />
              </svg>
            </div>
            <div className="absolute top-36 left-[600px] md:flex hidden">
              <svg width="117" height="122" viewBox="0 0 117 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.11699C6.80578 2.11699 85.6508 -2.05642 108.402 39.4503C129.094 77.201 95.601 111.752 83.4203 118" stroke="#3E7AE7" stroke-width="4" />
                <path d="M82 96V120H108" stroke="#3E7AE7" stroke-width="4" />
              </svg>
            </div>
            <div className="md:hidden absolute top-[70px] right-6">
              <svg width="67" height="41" viewBox="0 0 67 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.116943 5.25706C23.5 5.25646 45.142 -5.46337 59.5 9.99969C66 17 64.5 33 54.5 39" stroke="#3E7AE7" stroke-width="1.91998" />
                <path d="M54 28V39.5199H66.4799" stroke="#3E7AE7" stroke-width="1.91998" />
              </svg>

            </div>
          </div>



          <div className="flex flex-col md:flex-row gap-y-6 justify-between">
            <div className="md:w-1/2 max-w-[400px] w-full flex flex-col">
              <ul className="sm:text-base md:text-lg font-normal text-[18px] space-y-4">
                <h4 className="title text-[18px] sm:text-[24px] text-mainblue font-semibold mt-6 md:mt-12 mb-6">
                  {t("customers-wrap.right.card1.title")}
                </h4>
                {/* <li>{t("customers-wrap.right.card1.li1")}</li> */}
                <li>{t("customers-wrap.right.card1.li2")}</li>
                <li>{t("customers-wrap.right.card1.li3")}</li>
                <li>{t("customers-wrap.right.card1.li4")}</li>
              </ul>
            </div>
            <div className="md:w-1/2 max-w-[505px] w-full">
              <ul className="text-[16px] md:text-lg font-normal space-y-4">
                <p className="title text-[18px] sm:text-[24px] text-mainblue font-semibold mt-6 md:mt-12 mb-6">
                  Был сайт и реклама в интернете, но не получили нужого
                  результата
                </p>
                <li>{t("customers-wrap.right.card2.li1")}</li>
                <li>{t("customers-wrap.right.card2.li2")}</li>
                <li>{t("customers-wrap.right.card2.li3")}</li>
                <li>{t("customers-wrap.right.card2.li4")}</li>
                <li>{t("customers-wrap.right.card2.li5")}</li>
              </ul></div>
          </div>
        </div>
      </div>



      <div className="card-wrap w-[90%] md:w-[1140px] mx-auto sm:py-[80px] text-white">
        {cardData.map((item, i) => (
          <div key={i} className="home-customers-card py-12 relative">
            <div className="customers-card-head">
              <h1 className="title ">{item.title}</h1>
              <ul>
                {item.body.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
            {i === 0 && (
              <div className="absolute top-[-40px] right-0 z-[0] sm:flex hidden">
                <img src={'/son2.svg'} alt="img" className="" />
              </div>
            )}
            {i === 1 && (
              <div className="absolute top-0 right-0 z-[0] sm:flex hidden">
                <img src={'/son1.svg'} alt="img" className="w-full max-h-[388px] " />
              </div>
            )}
            {i === 2 && (
              <div className="absolute top-0 right-0 z-[0] sm:flex hidden">
                <img src={'/son3.svg'} alt="img" className="w-full max-h-[360px]" />
              </div>
            )}
            {i === 2 && (
              <div className="absolute bottom-0 right-0 w-[100px] h-[50px] bg-white sm:flex hidden">

              </div>
            )}
            <div className="w-full flex items-center mt-4 sm:mt-0 justify-center sm:justify-start sm:justify-end z-[1] sm:absolute bottom-8 sm:right-24">
              <div className={`flex justify-center ${(i == 2 && !isMobile) ? 'bg-mainblue text-white' : 'bg-white'} py-2 mt-3 h-[40px] sm:h-[80px] font-montserrat font-medium text-black w-[170px] sm:w-[400px] rounded-[5px] sm:rounded-lg`}>
                <Link
                  to={item.path}
                  onClick={() => window.scrollTo({ top: 0 })}
                  className="flex items-center justify-center h-full w-full"
                >
                  <p>{t("home-customers-card.button_title")}</p>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

};

export default memo(HomeCustomers);

