import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import LazyLoad from "react-lazyload";
// import SliderImage_1 from "/slide1.webp";
// import SliderImage_2 from "/slide2.webp";
// import SliderImage_3 from "/slide3.webp";
// import SliderImage_4 from "/slide4.webp";
// import SliderImage_5 from "/slide5.webp";
const images = [
    '/slide2.webp',
    '/slide3.webp',
    '/slide4.webp',
    '/slide5.webp',
];

const HomeConsultationSlider = () => {
    return (
        <div className="max-h-[200px] md:max-h-[749px] md:mb-32 w-full">
            <div className="flex flex-col  gap-y-12">
                <div className="md:flex hidden">
                    <LazyLoad height={381} offset={100}>

                        <img src="/new1.png" alt="" className="w-full max-h-[381px]" />
                    </LazyLoad>
                </div>

                <div className="overflow-x-scroll w-full flex gap-x-6 pb-8">
                    {images.map((image, index) => (
                        <div key={index} className="w-[263px] md:w-[347px] flex-shrink-0">
                            <LazyLoad height={229} offset={100}>
                                <img src={image} alt={`Slide ${index}`} className="w-full h-[173px] md:h-[229px] rounded-[10px] md:rounded-[20px]" />
                            </LazyLoad>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HomeConsultationSlider;


{/* <Carousel infiniteLoop className="h-full">
    <div className="w-full h-[300px] sm:h-auto md:h-[585px]">
        <img
            className="h-full w-full"
            src={'/slide1.webp'}
            alt="slider_img"
        />
    </div>
    <div className="w-full h-[300px] sm:h-auto md:h-[585px]">
        <img src={"/slide2.webp"} alt="slider_img" className="h-full w-full" />
    </div>
    <div className="w-full h-[300px] sm:h-auto md:h-[585px]">
        <img src={"/slide3.webp"} alt="slider_img" className="h-full w-full" />
    </div>
    <div className="w-full h-[300px] sm:h-auto md:h-[585px]">
        <img src={"/slide4.webp"} alt="slider_img" className="h-full w-full" />
    </div>
    <div className="w-full h-[300px] sm:h-auto md:h-[585px]">
        <img src={"/slide5.webp"} alt="slider_img" className="h-full w-full" />
    </div>
</Carousel> */}