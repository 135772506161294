import './Home-our-preferences.css'
import line from '../../assets/images/Home-image/Line 14.png'
import { useMediaQuery } from 'usehooks-ts';
const OurPreferencerCard = ({ image, text, idx }) => {
    const isMobile = useMediaQuery("(max-width: 500px)");
    return (
        <div className={`card ${(idx === 0 && !isMobile) && "absolute left-0"} ${(idx === 2 && !isMobile) && "absolute top-[40%] left-0"} ${(idx === 1 && !isMobile) && "absolute top-[20%] right-0"} ${(idx == 3 && !isMobile) && "absolute top-[65%] right-0"} ${(idx == 4 && !isMobile) && "absolute top-[85%] left-0"}`} >
            <div className="card-box_l">
                <img src={image} alt="img" />
            </div>
            <div className="card-box_r">
                <div className="card-content">
                    <p>{text}</p>
                    <img src={line} alt="" className="line" />
                </div>
            </div>
        </div>
    )
}

export default OurPreferencerCard