import "./Bottom-sticky-section.css";
import img1 from "../../assets/images/Home-image/comp.png";
import img2 from "../../assets/images/Home-image/phone.png";
import img3 from "../../assets/images/Home-image/users.png";
import img0 from "../../assets/images/Home-image/users.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LucideMessageCircle } from "lucide-react";
import useModalStore from "../../hooks/use-modal";
import { useMediaQuery } from "usehooks-ts";
import { memo } from "react";
import LazyLoad from "react-lazyload";

const cardData = [
    {
        id: 1,
        img: img0,
        path: "/",
    },
    {
        id: 2,
        img: img1,
        path: "/services",
    },
    {
        id: 3,
        img: img2,
        path: "/contact",
    },
    {
        id: 4,
        img: img3,
        path: "/contact",
    },
];

const BottomStickySection = () => {
    const { t } = useTranslation();
    const { openModal } = useModalStore();
    const isMobile = useMediaQuery("(max-width: 500px)");

    return (
        <div className="bottom-sticky-section z-[999999999999]">
            <div className="bottom-sticky-section-cards">
                {cardData.map((card, index) => (
                    <div key={card.id} className="bottom-sticky-section-card">
                        <div className="card-container">
                            <Link
                                to={card.path}
                                onClick={() => card.id < 2 ? openModal() : window.scrollTo({ top: 0 })}
                                className="h-full space-y-3 md:space-y-4"
                            >
                                <div className="flex items-center justify-center sm:h-auto">
                                    {card.id < 2 ? <LucideMessageCircle size={isMobile ? 26 : 40}
                                        color="white" /> : (
                                            <LazyLoad offset={100}>
                                                <img src={card.img} alt="" />
                                            </LazyLoad>
                                        )}
                                </div>
                                <p
                                    className="flex sm:h-auto h-[30px] leading-[12px]"
                                    style={{ wordBreak: "break-word", textAlign: "center" }}>
                                    {t(`bottom-sticky-section.${index}`)}
                                </p>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(BottomStickySection);
