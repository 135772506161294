import { Link, useLocation } from "react-router-dom";
import "./sticky-btns.css";
import { FaWhatsapp, FaTelegram, FaPhone, FaTimes, FaVk } from "react-icons/fa";
import { useState } from "react";
import { VscFeedback } from "react-icons/vsc";
import { toast } from "sonner";
import ClientFeedback from "../feedback/client.feedback";
import useModalStore from "../../hooks/use-modal";


const StickyBtns = () => {
    const location = useLocation();
    const [isContact, setIsContact] = useState(true);
        const { isOpen, closeModal, openModal } = useModalStore();


    const handlefeedback = () => {
        document.body.style.overflow = "hidden";
        openModal();

    };
    return (
        location.pathname !== "/sitemap.xml" &&
        <div className="relative w-full">
            {!isContact && (
                <div className="w-full fixed right-[3%] md:right-[1%] top-[37%] md:top-[51%] z-50 lg:top-[61%] gap-y-2 mr-[-4px] flex flex-col items-center justify-center">
                    <Link
                        to={"https://t.me/+79997103995"}
                        className={`border-2 text-[#00040f] hover:transition-all duration-200 hover:scale-110 cursor-pointer ease-in-out hover:text-white border-[#00040f] hover:bg-[#00040f] w-[50px] h-[50px] rounded-full bg-white flex items-center justify-center`}
                    >
                        <FaTelegram fontSize={"20px"} />
                    </Link>
                    <Link
                        to={"https://wa.clck.bar/79997103995"}
                        className={`border-2 text-[#00040f] hover:transition-all duration-200 hover:scale-110 cursor-pointer ease-in-out hover:text-white border-[#00040f] hover:bg-[#00040f] w-[50px] h-[50px] rounded-full bg-white flex items-center justify-center`}
                    >
                        <FaWhatsapp fontSize={"20px"} />
                    </Link>

                    <Link
                        to={"tel: +79997103995"}
                        className={`border-2 text-[#00040f] rotate-90 hover:transition-all duration-200 hover:scale-110 cursor-pointer ease-in-out hover:text-white border-[#00040f] hover:bg-[#00040f] w-[50px] h-[50px] rounded-full bg-white flex items-center justify-center`}
                    >
                        <FaPhone fontSize={"20px"} />
                    </Link>
                    <div className="w-[6px] h-[6px] rounded-full border border-[#00040f] bg-white"></div>
                    <div className="w-[6px] h-[6px] rounded-full border border-[#00040f] bg-white"></div>
                    <div className="w-[6px] h-[6px] rounded-full border border-[#00040f] bg-white"></div>
                    <div
                        onClick={() => setIsContact(true)}
                        className={`border-2 text-[#00040f] hover:transition-all duration-200 hover:scale-110 cursor-pointer ease-in-out hover:text-white border-[#00040f] hover:bg-[#00040f] w-[50px] h-[50px] rounded-full bg-white flex items-center justify-center`}
                    >
                        <FaTimes fontSize={"18px"} />
                    </div>
                </div>
            )}
            {isContact && (
                <div
                    onClick={() => setIsContact(false)}
                    className={`fixed right-[3%] md:right-[1%] top-[70%] border-2 text-[#00040f] hover:transition-all duration-200 hover:scale-110 cursor-pointer ease-in-out hover:text-white border-[#00040f] hover:bg-[#00040f] z-50 ${!isContact ? "w-[40px] h-[40px]" : "w-[50px] h-[50px]"
                        } rounded-full bg-white flex items-center justify-center rotate-90`}
                >
                    <FaPhone fontSize={"20px"} />
                </div>
            )}

            {isOpen && (
                <ClientFeedback closeModal={closeModal} />
            )}

            {/* {!isOpen && (
                <div
                    onClick={openModal}
                    className={`fixed right-[3%] md:right-[1%] top-[48%] border-2 text-[#00040f] hover:transition-all duration-200 hover:scale-110 cursor-pointer ease-in-out hover:text-white border-[#00040f] hover:bg-[#00040f] z-50 ${isOpen ? "w-[40px] h-[40px]" : "w-[50px] h-[50px]"
                        } rounded-full bg-white flex items-center justify-center`}
                >
                    <VscFeedback onClick={handlefeedback} fontSize={"20px"} />
                </div>
            )} */}
        </div>
    )

};

export default StickyBtns;
