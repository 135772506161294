import "./Home-main.css";
import line_top from "../../assets/images/Home-image/line_top.png";
import line_bottom from "../../assets/images/Home-image/line-bottom.png";
import { memo, useCallback, useEffect, useRef } from "react";
import LazyLoad from "react-lazyload";

const HomeMain = () => {
    const videoEl = useRef(null);
    const videoEl2 = useRef(null);

    const attemptPlay = useCallback(() => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch((error) => {
                console.error("Error attempting to play", error);
            });
    }, [videoEl]);

    const attemptPlay2 = useCallback(() => {
        videoEl2 &&
            videoEl2.current &&
            videoEl2.current.play().catch((error) => {
                console.error("Error attempting to play", error);
            });
    }, [videoEl2]);

    useEffect(() => {
        attemptPlay();
        attemptPlay2();
    }, [attemptPlay, attemptPlay2]);

    return (
        <>
            {/* mobile */}
            <div className="flex md:hidden h-[291px]">
                {/* <img src="/photo_2024-01-16_08-16-15.jpg" alt="" className="h-full"/> */}
                <div className="home">
                    <LazyLoad height={300} offset={100}>
                        <img
                            src="/animation.gif"
                            alt="Orqa Fon Animatsiyasi"
                            className="max-w-full h-[300px]"
                            loading="lazy"
                        />

                    </LazyLoad>
                </div>
            </div>
            {/* Laptop */}
            <div className="hidden md:flex max-w-[1280px] mx-auto h-[747px] relative bg-white">
                <div className="home">
                    <LazyLoad height={747} offset={100}>
                        <img
                            src="/famous.gif"
                            alt="Orqa Fon Animatsiyasi"
                            className="max-w-full max-h-[747px]"
                            loading="lazy"
                        />

                    </LazyLoad>
                </div>
            </div>
            <div className="main-element hidden">
                <div className="main-widget">
                    <div className="line-top">
                        <img
                            src={line_top}
                            alt=""
                            loading="lazy"
                        />
                    </div>
                    <div className="line-bottom">
                        <img
                            src={line_bottom}
                            alt=""
                            loading="lazy"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(HomeMain);
